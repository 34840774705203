import React from "react";

import { Link } from "react-router-dom";
import Wrapper from "./Wrapper.js";
import Slider from "react-slick";
import GlobalContext from "../GlobalContext";
import ModalCallBack from "./ModalCallBack";
import "../css/BannerDiscount.scss";

class BannerCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [],
    };
  }

  static contextType = GlobalContext;

  componentDidMount = () => {
    fetch(
      `https://dev.casada-russia.ru/api/bitrix/common/banners_catalog/?section=${this.props.section}`
    )
      .then((res) => {
        if (res.ok) {
          //console.log(res);
          return res.json();
        }
        throw new Error(res);
      })
      .then((result) => {
        this.setState({ banners: Object.values(result) });
      })
      .catch((e) => {
        //console.log(e);
      });
  };
  render() {
    const banners = this.state.banners;
    const sliderSettings = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className="__BannerCatalog" style={this.props.style}>
        {/* Старый вид */}
        {!this.props.compactView &&
          banners.map((banner, i) => {
            const isPreview = i > 0 && banner.preview.length > 0 ? true : false;
            let bannerPic = !!isPreview
              ? `${banner.preview}`
              : `${banner.pic}`;
            const buttonLink = !!banner.button_link
              ? banner.button_link
              : "/actions/products/";
            const buttonText = !!banner.button_text
              ? banner.button_text
              : "Посмотреть скидки";
            const cl = !isPreview ? "banner-large" : "banner-preview";
            const bannerHeight = !!isPreview ? 164 : 750;

            return (
              <Wrapper
                key={i}
                bid={`banner_${banner.code}`}
                className={cl}
                backgroundColor="transparent"
                background={{
                  inner: {
                    image: bannerPic,
                    position: "center bottom",
                    size: `auto ${bannerHeight}px`,
                  },
                }}
                minHeight={`${bannerHeight}px`}
                href={!banner.show_modal ? buttonLink : false}
                lazyLoad={false}
              >
                <div className="cover">
                  {!isPreview && (
                    <div
                      className="bottom-line"
                      style={{ textAlign: "center" }}
                    >
					  {!banner.show_modal && <Link
                        to={{
                          pathname: buttonLink,
                          query: {
                            scrollTo: "#complect-card-0",
                          },
                        }}
                      >
                        <button
                          className="buyButton"
                          style={{ marginBottom: "0px" }}
                        >
                          {buttonText}
                        </button>
                      </Link>}
					  {!!banner.show_modal && <>
                        <ModalCallBack buttonText={buttonText} />
                      </>}
                    </div>
                  )}
                </div>
              </Wrapper>
            );
          })}
        {/* Компактный вид, слайдер */}
        {!!this.props.compactView && (
          <Wrapper bid="banner-catalog-slider">
            <Slider {...sliderSettings}>
              {banners.map((banner, i) => {
                if (!banner.preview) return false;
                const buttonLink = !!banner.button_link
                  ? banner.button_link
                  : "/actions/products/";
                let bannerPic = `https://m.casada-russia.ru${banner.preview}`;
                return (
                  <div className="slide">
                    <Link to={buttonLink}>
                      <img src={bannerPic} width="350" height="164" alt="" />
                    </Link>
                  </div>
                );
              })}
            </Slider>
          </Wrapper>
        )}
      </div>
    );
  }
}

export default BannerCatalog;
