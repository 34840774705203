import React from "react";
import { Link } from "react-router-dom";
import PopupManager from "../PopupManager";
import Popup from "./Popup";
import TCaption from "./TCaption";
//import FormMaterial from "./FormMaterial";
import FormatText from "./FormatText";
import SetProduct from "./SetProduct";

const FormMaterial = React.lazy(() => import("./FormMaterial.js"));

const SetCard = ({ set, propsProducts }) => {
  let price = {};
  if (set.type === "set") {
    price = set.price;
  }
  const ids = set.products.map((id) => id.id);
  const idsList = set.products.map((product) => {
    return product.id;
  });
  const setProducts = Object.keys(propsProducts)
    .filter((id) => ids.includes(id))
    .map((id) => propsProducts[id]);
  const setProductNames = setProducts
    .map((product) => {
      return product.name;
    })
    .join(" + ");
  const sortedProducts = setProducts.sort((a, b) => {
    if (a.sort < b.sort) return -1;
    if (a.sort > b.sort) return 1;
    return 0;
  });

  const toCurrency = (val) => {
    return val.toLocaleString("ru-RU");
  };

  return (
    <>
      <TCaption.Wrapper center>
        <TCaption.Title>{set.name}</TCaption.Title>
        <TCaption.Sub>{set.description}</TCaption.Sub>
      </TCaption.Wrapper>
      {sortedProducts.map((product, key) => (
        <SetProduct
          key={key}
          setType={set.type}
          setId={set.id}
          product={product}
        />
      ))}

      {!!price.base && !!price.final && (
        <div>
          <div className="complect-card__price">{toCurrency(price.base)} ₽</div>
          <div className="complect-card__item-discount-price">
            {toCurrency(price.final)} ₽
          </div>
        </div>
      )}

      <div className="complect-card__time">Только до {set.till}</div>

      <div className="complect-card__button">
        <PopupManager>
          <Popup
            popupId={`popup-order`}
            hook
            show={false}
            backdrop
            animation
            closeButton={false}
            button={<div className="buyButton buyButton--block">Купить</div>}
            submitClass="submit-button-red"
          >
            {{
              header: "",
              body: (
                <div>
                  <div style={{ textAlign: "left", position: "relative" }}>
                    <div className="button-order-header">
                      <div className="button-order-header__details">
                        <div className="button-order-header__details-name">
                          {set.name} {set.description}
                        </div>
                        <div className="button-order-header__details-description">
                          {setProductNames}
                        </div>
                        <TCaption.Wrapper left>
                          <TCaption.Spacer height="10px" />
                          <TCaption.OldPrice
                            style={{
                              marginLeft: "5px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {toCurrency(price.base)}&nbsp;₽
                          </TCaption.OldPrice>

                          {!!price.final && (
                            <TCaption.Sub
                              style={{
                                fontWeight: "600",
                                whiteSpace: "nowrap",
                                padding: 0,
                              }}
                            >
                              {toCurrency(price.final)}&nbsp;₽
                            </TCaption.Sub>
                          )}
                        </TCaption.Wrapper>
                      </div>
                    </div>
                    <React.Suspense fallback={<></>}>
                      <FormMaterial
                        formId="form-order"
                        submitUrl={`https://${process.env.REACT_APP_DESKTOP_DOMAIN}/api/casada-mobile/set-order/`}
                        method="POST"
                        submitText={
                          <div className="submit-button-red">Заказать</div>
                        }
                        parentPopup="popup-order"
                      >
                        {[
                          {
                            type: "phone",
                            name: "phone",
                            label: "Ваш номер телефона",
                            placeholder: "Телефон",
                            required: true,
                          },
                          {
                            type: "hidden",
                            name: "product_id",
                            value: idsList,
                          },
                        ]}
                      </FormMaterial>
                    </React.Suspense>
                    <FormatText type="text-small">
                      Оформляя заказ, вы даете согласие на <br />
                      <Link to="/agree/" className="link-policy hl-red">
                        обработку персональных данных
                      </Link>
                    </FormatText>
                  </div>
                </div>
              ),
              footer: "",
              cancel: {
                // text: "",
                // callback: handleCookieDecline
              },
              accept: {
                // text: submitButton
                // callback: handleCookieAccept
              },
            }}
          </Popup>
        </PopupManager>
      </div>
    </>
  );
};

export default SetCard;
