import React from "react";
import SetCard from "./SetCard";

const SetList = ({ sets, propsProducts }) => {
  return (
    <>
      {sets.map((set, key) => (
        <div id={`complect-card-${key}`} className="complect-card">
          <SetCard set={set} propsProducts={propsProducts} />
        </div>
      ))}
    </>
  );
};

export default SetList;
