import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./style.scss";

import Wrapper from "../Wrapper";
import TCaption from "../TCaption";

import Slider from "react-slick";
import BlockYTV3 from "../BlockYTV3.js";
import BlockRTV from "../BlockRTV.js";
import GTMClassWrapper from "../GTMClassWrapper";

/*function ArrowPrev(props) {
  const { className, styleObj, onClick } = props;
  return (
	<div className={className} 
		 style={{...styleObj}}
		 onClick={onClick}
	>
		<IconArrowPrev />
	</div>
  );
}
function ArrowNext(props) {
  const { className, styleObj, onClick } = props;
  return (
    <div className={className} 
		 style={{...styleObj}}
		 onClick={onClick}
	>
		<IconArrowNext />
	</div>
  );
}*/

function VideoSlider(props) {
  const [video, setVideo] = useState({ video: [] });
  useEffect(() => {
	if(!props.video) {
		fetch("/assets/data/video.json")
		.then((response) => response.json())
		.then((result) => {
			if (!!props.section) {
			  result = result.filter(
				(slide) => slide.section.indexOf(props.section) > -1
			  );
			}
			setVideo(result);
		});
	} else {
		if(!!props.video && props.video.length > 0) setVideo(props.video);
	}
  }, []);
  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: !!props.visibleCount ? props.visibleCount : 2.5,
    swipeToSlide: true,
    //prevArrow: <ArrowPrev />,
    //nextArrow: <ArrowNext />
  };

	  console.log(!!props.video ? props.video : []);
  if (video.length > 0) {
    return (
      <Wrapper bid="video-slider" lazyLoad={props.lazyLoad === false ? false : true}>
        <TCaption.Wrapper left>
          <TCaption.Spacer height="32px" />
          <TCaption.AccentTitle style={{ fontSize: "20px" }}>
            {!!props.title ? props.title : "Звёзды рекомендуют"}
          </TCaption.AccentTitle>
        </TCaption.Wrapper>
        <TCaption.Spacer height="12px" />
        <Slider {...settings}>
          {video.map((slide, i) => {
            return (
              <div className="slide">
                <div
				  className={`preview ${props.slideClass}`}
                  style={{
                    backgroundImage: `url("https://casada-russia.ru${slide.preview}")`,
                  }}
                >
                  <GTMClassWrapper className="gtm_btn_youtube_video_preview">
					{slide.provider == 'YouTube' ? <BlockYTV3 video={slide.video} start={slide.start} /> :
						<BlockRTV video={slide.rutube} start={slide.start} />
					}
                  </GTMClassWrapper>
                </div>
                <div className={`banners-link-semibold-16-19 color-base-black title ${props.slideClass}`}>
                  {slide.title}
                </div>
                <div className={`small-text-12-16 color-base-grey sub ${props.slideClass}`}>
                  {slide.sub}
                </div>
              </div>
            );
          })}
        </Slider>
      </Wrapper>
    );
  } else return <></>;
}

export default VideoSlider;
