import React from "react";
import { withRouter } from "react-router-dom";

import { Link } from "react-router-dom";
import $ from "jquery";
import { Range } from "rc-slider";
import MinPrice from "./MinPrice.js";
import SetList from "./SetList";
import "rc-slider/assets/index.css";
import ButtonOrder from "./ButtonOrder.js";
import TCaption from "./TCaption.js";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import "../css/Search.scss";
import IconChecked from "../img/icons/checked-grey.svg";
import IconPlusSign from "../img/icons/more-plus-v2.svg";

import CatalogHideButton from "../img/icons/back-white.svg";
import Logo from "../img/logo.svg";

import Button from "./Button";
import TestDriveV5 from "./TestDriveV5";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      price: {
        min: 0,
        max: 1000000,
      },
      initialPrice: {
        min: 0,
        max: 1000000,
      },
      pSort: [],
      activeProducts: [],
      menuItems: props.menuItems,
      initialMenuItems: props.menuItems,
      colors: [],
      filter: true,
      count: 0,
      show: false,
      expanded: false,
      sets: [],
      owlOptions: {
        margin: 20,
        items: !this.props.showPopular ? 3 : 2,
        autoWidth: !this.props.showPopular ? false : true,
        stagePadding: !this.props.showPopular ? 40 : 0,
        nav: false,
        dots: false,
        rewind: false,
        loop: !this.props.showPopular ? true : false,
        autoplay: true,
        autoplayTimeout: 3500,
        fluidSpeed: 3000,
        autoplaySpeed: 3000,
        autoplayHoverPause: true,
        fallbackEasing: "linear",
      },
    };
    this.popularStyle = !!props.showPopular ? { width: "160px" } : {};
    this.owlEvents = {};
    if (this.props.showWrappers) {
      this.showWrappers = this.props.showWrappers;
    } else {
      this.showWrappers = [
        "search",
        "warranty",
        "services",
        "personal-discount",
      ];
    }
  }

  handlerChangePrice = (range) => {
    const newState = {
      price: {
        min: range[0],
        max: range[1],
      },
    };
    this.recountModels(this.state.filter, newState.price, this.state.colors);
    this.setState(newState);
  };

  handlerPickColor = (e) => {
    let element = e.target;
    if (!element.dataset.filter) {
      element = e.target.parentElement;
    }
    const { filter } = element.dataset;
    const newColors = this.state.colors;
    if (element.classList.contains("active")) {
      element.classList.remove("active");
      for (let i = 0; i < newColors.length; i++) {
        if (newColors[i] === filter) {
          newColors.splice(i, 1);
        }
      }
    } else {
      element.classList.add("active");
      newColors.push(filter);
    }
    this.setState({ colors: newColors });
    this.recountModels(this.state.filter, this.state.price, newColors);
  };

  handlerPickAllColors = (e) => {
    const options = document.getElementsByClassName("color-option");
    const newColors = [];
    for (const option of options) {
      newColors.push(option.dataset.filter);
      option.classList.add("active");
    }
    this.setState({ colors: newColors });
    this.recountModels(this.state.filter, this.state.price, newColors);
  };

  recountModels = (update, price, colors, state) => {
    const products = state ? state.products : this.state.products;

    let count = 0;
    let newPrice = 0;
    const newProducts = [];
    let newColors = [];
    const newProductStyle = {};
    const newMenuItems = [
      {
        bid: "search",
        name: "Назад в каталог",
        onClick: this.handlerHideFilter.bind(this),
      },
    ];
    for (let i = 0; i < products.length; i++) {
      const product = JSON.parse(JSON.stringify(products[i]));
	  
      newColors = [];
      for (let j = 0; j < product.colors.length; j++) {
        newPrice = parseInt(product.colors[j].newPrice.replace(" ", ""), 10);

        // Фильтр по наличию скидки
        if (
          !!this.props.filterByDiscount &&
          product.colors[j].newPrice === product.colors[j].oldPrice
        ) {
          if (!newProductStyle[product.code])
            newProductStyle[product.code] = { opacity: "0.3" };
          continue;
        }

        if (typeof product.colors[j].color === "string") {
          // фильтр по цене
          if (newPrice < price.min || newPrice > price.max) {
            if (!newProductStyle[product.code])
              newProductStyle[product.code] = { opacity: "0.3" };
            continue;
          } else {
            // фильтр по цвету
            if (
              colors.length > 0 &&
              colors.indexOf(product.colors[j].color) === -1
            ) {
              if (!newProductStyle[product.code])
                newProductStyle[product.code] = { opacity: "0.3" };
              continue;
            } else {
              newProductStyle[product.code] = {};
            }
          }
        } else if (typeof product.colors[j].color === "object") {
          // фильтр по цене
          if (newPrice < price.min || newPrice > price.max) {
            if (!newProductStyle[product.code])
              newProductStyle[product.code] = { opacity: "0.3" };
            continue;
          } else {
            // фильтр по цвету
            if (colors.length > 0) {
              let flag = true;
              for (const k in product.colors[j].color) {
                if (colors.indexOf(product.colors[j].color[k]) !== -1)
                  flag = false;
              }
              if (flag) {
                if (!newProductStyle[product.code])
                  newProductStyle[product.code] = { opacity: "0.3" };
                continue;
              } else {
                newProductStyle[product.code] = {};
              }
            }
          }
        }
        newColors.push(product.colors[j]);
        count++;
      }
      if (newColors.length > 0) {
        product.colors = newColors;
        if (!!this.props.filterByDiscount) {
          // Сортировка в скидках
          product.sort = Math.ceil(
            1000000000 / parseInt(product.price_discount)
          );
        } else if (this.props.targetCategory == "chairs") {
          // Сортировка в цветовых вариантах
          product.sort = parseInt(product.price_discount);
        }
        newProducts[product.sort] = product;
        newMenuItems.push({
          bid: product.code,
          name: product.name,
          onClick: this.handlerProductLink.bind(this, product),
        });
      }
    }
    const newState = {
      count,
      menuItems: newMenuItems,
      productStyle: newProductStyle,
    };

    if (update) {
      // Сортировка в ленте товаров (не в слайдере)
      newState.activeProducts = newProducts.filter(function (item) {
        return item !== null;
      });
    } else {
      newState.activeProducts = this.state.pSort;
    }

    this.setState(newState);
  };

  handlerProductLink = (product) => {
    const section = this.props.targetCategory
      ? this.props.targetCategory
      : product.section;
    window.location.href = `/${section}/${product.code}/`;
  };

  handlerFilterReset = (e) => {
    let newState = {};
    let newPrice;
    let newColors;
    newPrice = {
      min: this.state.initialPrice.min,
      max: this.state.initialPrice.max,
    };
    newColors = [];
    $(".color-option").removeClass("active");
    newState = { filter: true, colors: newColors, price: newPrice };

    /* else {
            newPrice = {min: this.state.price.min, max: this.state.price.max};
            newColors = this.state.colors;
            newState = {filter: true};
        } */
    this.recountModels(newState.filter, newPrice, newColors);
    this.setState(newState);
  };

  handlerScrollToCard = (e) => {
    let element = e.target;
    if (!element.dataset.code) {
      element = element.parentElement;
    }
    /* $('html, body').animate({scrollTop: $('.product-card[data-code="'+element.dataset.code+'"]').offset().top}); */
    const url = `/${this.props.targetCategory}/${element.dataset.code}/`;
    // this.props.history.push(url);
  };

  handlerShowFilter = () => {
    this.recountModels(this.state.filter, this.state.price, this.state.colors);
    this.setState({ show: true });
  };

  handlerHideFilter = () => {
    if (this.props.backToHistory) {
      this.props.history.goBack();
      return;
    }

    this.handlerFilterReset();
    this.setState({ show: false, menuItems: this.state.initialMenuItems });
    const body = $("html, body");
    body.stop().animate({ scrollTop: 0 }, 500);
  };

  handlerScrollToModels = () => {
    let wrapperId = "#search";
    if (this.props.wrapperId) {
      wrapperId = "#" + this.props.wrapperId;
    }
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $(wrapperId + " .products-wrapper")
          .eq(0)
          .offset().top,
      },
      500
    );
  };

  declOfNum(number, words) {
    return words[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
    ];
  }

  render() {
    const OwlCarousel = React.lazy(() => import("react-owl-carousel2"));
    if (this.state.show) {
      for (const wrapper of $(".__Wrapper")) {
        if (this.showWrappers && this.showWrappers.indexOf(wrapper.id) === -1) {
          $(wrapper).hide();
        }
      }
    } else {
      $(".__Wrapper").show();
    }

    const priceStep = Math.ceil(
      (this.state.initialPrice.max - this.state.initialPrice.min) / 10
    );

    this.carouselInstance++;
	
	const objStyle = !!this.props.style ? this.props.style : {};
    return (
      <>
        <div className="__Search" style={objStyle}>
          <div
            style={
              !this.state.show
                ? { display: "none" }
                : { backgroundImage: `url(${CatalogHideButton})` }
            }
            onClick={this.handlerHideFilter}
            className="button-back"
          />
          {!this.state.show && !this.props.noLogo ? (
            <header>
              <Link to="/" className="logo">
                <img src={Logo} alt="Casada Russia logo" />
              </Link>
            </header>
          ) : (
            ""
          )}
          <div className="filter-container" data-show={this.state.show}>
            <div
              className="hidden-container"
              style={this.props.filterHidden ? { display: "none" } : {}}
            >
              {(this.state.pSort.length > 3 || !!this.props.showPopular) && (
                <React.Suspense fallback={<></>}>
                  <OwlCarousel
                    options={this.state.owlOptions}
                    events={this.owlEvents}
                    key={this.carouselInstance}
                  >
                    {this.state.pSort.map((product, sort) => {
                      if (
                        (!product.available ||
                        (this.props.excludeProduct &&
                          this.props.excludeProduct.id === product.id)) && product.code != 'pollux'
                      )
                        return false;

                      return (
                        <Link
                          to={`/${
                            this.props.targetCategory
                              ? this.props.targetCategory
                              : this.getProductSection(product)
                          }/${product.code}/`}
                          key={sort}
                          style={
                            !!this.props.showPopular ? this.popularStyle : {}
                          }
                        >
                          <div
                            data-code={product.code}
                            onClick={this.handlerScrollToCard}
                            className="slide"
                            style={this.state.productStyle[product.code]}
                          >
                            {!!this.props.showPopular ? (
                              <img
                                className="chair-pic"
                                width="160"
                                height="160"
                                src={product.image + "?w=160"}
                                alt={product.name}
                                style={{ maxWidth: "160px" }}
                              />
                            ) : (
                              <img
                                className="chair-pic"
                                width="100"
                                height="100"
                                src={product.image + "?w=100"}
                                alt={product.name}
                                style={{ maxWidth: "100px" }}
                              />
                            )}

                            <div
                              style={{
                                width: !!this.props.showPopular
                                  ? "160px"
                                  : "100%",
                              }}
                            >
                              <TCaption.Wrapper center>
                                {!!this.props.showPopular ? (
                                  <div style={{ height: "54px" }}>
                                    <TCaption.Sub>{product.name}</TCaption.Sub>
                                  </div>
                                ) : (
                                  <div style={{ color: "#000000" }}>
                                    {product.name}
                                  </div>
                                )}
                                {!!this.props.showPopular && (
                                  <div
                                    style={{ color: "#666666", height: "54px" }}
                                  >
                                    {product.sub}
                                  </div>
                                )}
                              </TCaption.Wrapper>
                            </div>

                            {!!this.props.showPopular ? (
                              <div className="prices">
                                <div>
                                  {product.price_base !==
                                  product.price_discount ? (
                                    <div className="oldPrice">
                                      {product.colors[0].oldPrice} ₽
                                    </div>
                                  ) : (
                                    <div style={{ height: "19px" }} />
                                  )}
                                </div>
                                <div className="newPrice">
                                  {product.colors[0].newPrice} ₽
                                </div>
                              </div>
                            ) : (
                              <div style={{ color: "#A4A4A4" }}>
                                <MinPrice productId={product.id} /> ₽
                              </div>
                            )}

                            {product.tags && product.tags.includes("new") && (
                              <div style={{ fontSize: 10, color: "#D71B39" }}>
                                Новинка
                              </div>
                            )}
                          </div>
                        </Link>
                      );
                    })}
                  </OwlCarousel>
                </React.Suspense>
              )}

              {this.state.pSort.length <= 3 && !this.props.showPopular && (
                <div className="products-search">
                  {this.state.pSort.map((product, sort) => {
                    if (!product.available && product.code != 'pollux') return false;
                    return (
                      <Link
                        to={`/${
                          this.props.targetCategory
                            ? this.props.targetCategory
                            : this.getProductSection(product)
                        }/${product.code}/`}
                        key={sort}
                      >
                        <div
                          data-code={product.code}
                          onClick={this.handlerScrollToCard}
                          className="slide"
                          style={this.state.productStyle[product.code]}
                        >
                          <img
                            className="chair-pic"
                            width="100"
                            height="100"
                            src={product.image + "?w=100"}
                            alt={product.name}
                            style={{ maxWidth: "100px" }}
                          />
                          <div>{product.name}</div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              )}

              <TCaption.Spacer height="45px" />
              {!this.state.show && !this.props.hideFilterButton ? (
                <div className="button-wrapper">
                  <Button
                    className="button-expand button-search-results"
                    label={
                      this.props.filterShowButton
                        ? this.props.filterShowButton
                        : "Все модели"
                    }
                    type="link"
                    arrowRight
                    textCenter
                    onClick={this.handlerShowFilter}
                  />
                  <TCaption.Spacer height="32px" />
                </div>
              ) : (
                ""
              )}
              {this.props.linkHref && this.props.linkButton && (
                <div className="button-wrapper">
                  <Button
                    className="button-expand button-search-results"
                    label={
                      this.props.linkButton
                        ? this.props.linkButton
                        : "Все модели"
                    }
                    type="link"
                    arrowRight
                    textCenter
                    href={this.props.linkHref}
                  />
                  <TCaption.Spacer height="32px" />
                </div>
              )}
            </div>
            <div
              className="filter-wrapper"
              style={this.state.show ? {} : { display: "none" }}
            >
              <div
                className="hidden-container"
                style={this.props.filterHidden ? { display: "none" } : {}}
              >
                <div className="spacer" />
                <TCaption.Spacer height="29px" />
                <div className="text-header">Цена</div>
                <div className="price-values">
                  <span>{this.state.price.min.toLocaleString("ru")}</span> -{" "}
                  <span>{this.state.price.max.toLocaleString("ru")}</span>
                </div>
                <div className="price-slider-wrapper">
                  <Range
                    className="price-slider"
                    min={this.state.initialPrice.min}
                    max={this.state.initialPrice.max}
                    step={priceStep}
                    value={[this.state.price.min, this.state.price.max]}
                    onChange={this.handlerChangePrice}
                  />
                </div>
                <TCaption.Spacer height="24px" />
                <div className="text-header">
                  Цвет{" "}
                  <div
                    onClick={this.handlerPickAllColors}
                    className="option-all"
                  >
                    Выбрать все
                  </div>
                </div>
                <TCaption.Spacer height="7px" />
                <div className="colors-filter">
                  <div
                    onClick={this.handlerPickColor}
                    className="color-option"
                    data-filter="white"
                  >
                    <div className="cover" />
                  </div>
                  <div
                    onClick={this.handlerPickColor}
                    className="color-option"
                    data-filter="red"
                  >
                    <div className="cover" />
                  </div>
                  <div
                    onClick={this.handlerPickColor}
                    className="color-option"
                    data-filter="black"
                  >
                    <div className="cover" />
                  </div>
                  <div
                    onClick={this.handlerPickColor}
                    className="color-option"
                    data-filter="grey"
                  >
                    <div className="cover" />
                  </div>
                  <div
                    onClick={this.handlerPickColor}
                    className="color-option"
                    data-filter="brown"
                  >
                    <div className="cover" />
                  </div>
                  <div
                    onClick={this.handlerPickColor}
                    className="color-option"
                    data-filter="beige"
                  >
                    <div className="cover" />
                  </div>
                  <div
                    onClick={this.handlerPickColor}
                    className="color-option"
                    data-filter="orange"
                  >
                    <div className="cover" />
                  </div>
                </div>
                {this.state.colors.length === 0 &&
                this.state.price.min === this.state.initialPrice.min &&
                this.state.price.max === this.state.initialPrice.max ? (
                  ""
                ) : (
                  <div>
                    <div
                      className="filter-apply"
                      onClick={this.handlerScrollToModels}
                    >
                      Найдено {this.state.count}{" "}
                      {this.declOfNum(this.state.count, [
                        "модель",
                        "модели",
                        "моделей",
                      ])}
                    </div>
                    <div className="reminder" onClick={this.handlerFilterReset}>
                      Сбросить фильтр
                    </div>
                  </div>
                )}
                <TCaption.Spacer height="50px" />

                <div className="button-wrapper">
                  {this.state.expanded ? (
                    <div className="button-collapse">
                      <img src={IconPlusSign} alt="" />
                      <div className="collapse-hint">Свернуть</div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="products-wrapper">
                {this.props.setsEnabled && this.state.sets.length > 0 && (
                  <SetList
                    sets={this.state.sets}
                    propsProducts={this.props.products}
                  />
                )}

                {this.state.products &&
                  this.state.activeProducts.map((product, i) => {
                    let TestDrive = "";
                    if (i + 1 === this.props.testDrivePosition) {
                      TestDrive = (
                        <TestDriveV5
                          product={this.props.testDriveProduct}
                          customTitle={
                            <span style={{ fontWeight: "600" }}>
                              {this.props.testDriveTitle}
                            </span>
                          }
                          type="product"
                        />
                      );
                    }
                    if (!product.available) return "";
                    else
                      return (
                        <>
                          <div
                            key={i}
                            id={`card-${product.code}`}
                            className="product-card"
                            data-code={product.code}
                          >
                            <TCaption.Wrapper center>
                              <TCaption.Title>{product.name}</TCaption.Title>
                              <TCaption.Sub>
                                <div className="sub">{product.sub}</div>
                              </TCaption.Sub>
                              <TCaption.Spacer height="20px" />
                              {product.features
                                ? product.features.map((item, j) => (
                                    <div key={j} className="feature">
                                      <img src={IconChecked} alt="" /> {item}
                                    </div>
                                  ))
                                : ""}
                              {product.colors
                                ? product.colors.map((item, j) => (
                                    <div
                                      key={j}
                                      className="variant"
                                      data-color={item.color}
                                    >
                                      {product.tags &&
                                        product.tags.includes(
                                          " labelBrainInSearchList"
                                        ) && (
                                          <div className="label-brain">
                                            массаж для
                                            <br />
                                            мозга
                                          </div>
                                        )}

                                      <div>
                                        <Link
                                          to={`/${
                                            this.props.targetCategory
                                              ? this.props.targetCategory
                                              : this.getProductSection(product)
                                          }/${product.code}/${
                                            item.code ? `${item.code}/` : ""
                                          }`}
                                        >
                                          <img
                                            width="280"
                                            height="280"
                                            src={item.image + "?w=280"}
                                            alt=""
                                          />
                                        </Link>
                                      </div>
                                      {item.oldPrice === item.newPrice ? (
                                        <div className="new-price">
                                          {item.newPrice} ₽
                                        </div>
                                      ) : (
                                        <div className="prices">
                                          <div className="new-price">
                                            {item.newPrice} ₽
                                          </div>
                                          <div className="old-price">
                                            {item.oldPrice} ₽
                                          </div>
                                        </div>
                                      )}

                                      {this.props.canBuy ? (
                                        <div>
                                          <TCaption.Spacer height="30px" />
                                          <ButtonOrder
                                            short
                                            productId={product.id}
                                            products={this.props.products}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      <div className="link-wrapper">
                                        <Link
                                          to={`/${
                                            this.props.targetCategory
                                              ? this.props.targetCategory
                                              : this.getProductSection(product)
                                          }/${product.code}/${
                                            item.code ? `${item.code}/` : ""
                                          }`}
                                        >
                                          <Button
                                            label="Подробнее"
                                            type="link"
                                            arrowRight
                                            textCenter
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  ))
                                : ""}
                            </TCaption.Wrapper>
                          </div>
                          {TestDrive}
                        </>
                      );
                  })}

                {this.state.sets.length > 0 ? (
                  <TCaption.Spacer height="72px" />
                ) : (
                  ""
                )}
                {/*<div*/}
                {/*  style={this.props.filterHidden ? { display: "none" } : {}}*/}
                {/*>*/}
                {/*  <Button label={this.props.pageName} type='link' arrowRight textCenter onClick={this.handlerHideFilter} />*/}
                {/*</div>*/}
                <TCaption.Spacer height="40px" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidMount = () => {
    const { count } = this.state;

    if (this.props.setsEnabled) {
      fetch("/assets/data/sets.json")
        .then((res) => res.json())
        .then((sets) => {
          this.setState({ sets });
        });
    }

    const newState = this.parseProducts();
    this.setState(newState);

    if (this.props.show) {
      this.setState({ show: true });
      this.recountModels(
        this.state.filter,
        this.state.price,
        this.state.colors,
        newState
      );
    }

    if (
      this.props.history.location.query &&
      this.props.history.location.query.scrollTo
    ) {
      setTimeout(() => {
        const scrollToEl = document.querySelector(
          this.props.history.location.query.scrollTo
        );

        if (scrollToEl) scrollToEl.scrollIntoView();
      }, 500);
    }
  };

  parseProducts = () => {
    const { products } = this.props;
    if (products) {
      const pSort = [];
      const newState = { products: [], count: 0, productStyle: {} };

      let minPrice = false;
      let maxPrice = false;

      for (const id in products) {
        if (
          !!this.props.showPopular ||
          this.props.category.indexOf(products[id].section) !== -1
        ) {
          /* Заглушка - убрать, когда будет готова загрузка из json в Битриксе */
          if (products[id].colors) {
            if (!products[id].sections)
              products[id].sections = [products[id].section];

            // Если включён фильтр популярных товаров и у товара popular = false, пропускаем
			
            if (!!this.props.showPopular && !products[id].popular) {
              continue;
            }
            // Если не целевая категория, пропускаем
            else if (
              !!this.props.targetCategory &&
              products[id].sections.indexOf(this.props.targetCategory) === -1
            )
              continue;

            // Вычисляем диапазон цен
            const variants = products[id].colors.slice();
            for (const i in variants) {
              const variantPrice = parseInt(
                variants[i].newPrice.replace(" ", "")
              );
              const variantOldPrice = parseInt(
                variants[i].oldPrice.replace(" ", "")
              );
              if (!minPrice) {
                minPrice = variantPrice;
              } else if (variantPrice < minPrice) {
                minPrice = variantPrice;
              }
              if (!maxPrice) {
                maxPrice = variantPrice;
              } else if (variantPrice > maxPrice) {
                maxPrice = variantPrice;
              }
            }

            // Сортировка
            if (products[id].sort) {
              if (!!this.props.filterByDiscount) {
                let sortIndex = Math.ceil(1000000000/parseInt(products[id].price_discount));
				while(!!pSort[sortIndex]) {
					sortIndex++;
				}
				pSort[sortIndex] = products[id];
              } else if (products[id].section == "chairs") {
				let sortIndex = Math.ceil(1000000000/parseInt(products[id].price_discount));
				while(!!pSort[sortIndex]) {
					sortIndex++;
				}
				pSort[sortIndex] = products[id];
              } else {
                if (!!pSort[products[id].sort]) products[id].sort++;
                pSort[products[id].sort] = products[id];
              }
            }

            newState.count += products[id].colors.length;
            if (products[id].colors.length > 0) {
              newState.products.push(products[id]);
              newState.productStyle[products[id].code] = {};
            }
            // newState.menuItems.push({bid: products[id].code, name: products[id].name});
          }
        }
      }
	  
      newState.price = { min: minPrice, max: maxPrice };
      newState.initialPrice = { min: minPrice, max: maxPrice };
      newState.pSort = pSort.filter(function (item) {
        return item !== null;
      });

      if (newState.products.length < 4) {
        this.state.owlOptions.items = newState.products.length;
      }

      return newState;
    }
  };

  getProductSection = (product) => {
    let section;
    if (product.sections[0] !== "massagers") {
      section = product.sections[0];
    } else {
      section = product.sections[1];
    }
    return section;
  };

  componentWillUnmount = () => {};
}

export default withRouter(Search);
