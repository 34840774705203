import React from "react";

import "../css/BlockRTV.scss";
import IconPlay from "../img/icons/play.svg";
import IconClose from "../img/icons/delete-white.svg";

import RutubeVideo from "./RutubeVideo";
import ModalDialog from "./ModalDialog.js";

class BlockRTV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isFixed: props.fixed ? props.fixed : false,
      isLink: props.link ? props.link : false,
    };
    this.ref = React.createRef();
    this.YT = false;
  }

  render() {
    const poster = "";
    let cl = "button-play";
    if (this.state.isFixed) {
      cl += " fixed";
    }

    let componentClass = "__BlockRTV";
    if (this.state.isLink) componentClass += " isLink";
    return (
      <div
        className={componentClass}
        ref={this.ref}
        style={{
          backgroundImage: `url(${this.props.poster})`,
          zIndex: this.props.zIndex,
        }}
      >
        {!this.state.isLink ? (!!this.props.wrap ? <div className="wrap" onClick={this.handlerShow}>
		  <div className={cl}
            style={{ backgroundImage: `url(${IconPlay})` }}
          /></div> : <div
		    onClick={this.handlerShow}
            className={cl}
            style={{ backgroundImage: `url(${IconPlay})` }}
          />) : (
          <div onClick={this.handlerShow} className="link-play" style={!!this.props.text ? {padding:"0"} : {}}>
            {!!this.props.text && <span style={{textDecoration:"underline", padding: "0"}}>{this.props.text}</span>}
			{!!this.props.button && this.props.button}
			{!this.props.text && !this.props.button && <><svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
			<path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 11C20 15.9706 15.971 20 11 20C6.029 20 2 15.9706 2 11C2 6.02944 6.029 2 11 2C15.971 2 20 6.02944 20 11ZM22 11C22 17.0751 17.075 22 11 22C4.9249 22 0 17.0751 0 11C0 4.92487 4.9249 0 11 0C17.075 0 22 4.92487 22 11Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.48564 6.82536C8.81911 6.45506 8 6.93703 8 7.69951V14.3005C8 15.063 8.81911 15.5449 9.48564 15.1746L15.4265 11.8742C16.1123 11.4932 16.1123 10.5068 15.4265 10.1258L9.48564 6.82536Z"
                fill="black"
			  />
            </svg><span>Смотреть видео</span></>}
          </div>
        )}
        <ModalDialog
          className={"youtube-modal"+(!!this.props.short ? " short" : "")}
          show={this.state.isOpen}
          hideCallback={this.handlerHide}
          header={
            <div className="button-close" onClick={this.handlerHide.bind(this)}>
              <img src={IconClose} alt="" /> Закрыть
            </div>
          }
          body={
            <RutubeVideo
			  short
              video={this.props.video}
			  start={this.props.start}
            />
          }
        />
        {!!this.state.isOpen && (
          <style>.fade.modal-backdrop.show {"{opacity:0.9;}"}</style>
        )}
      </div>
    );
  }

  componentDidMount() {
    if (this.props.fixed) {
      window.addEventListener("scroll", this.onScroll.bind(this), false);
    }
    window.addEventListener(
      "orientationchange",
      this.onOrientationChange.bind(this),
      false
    );
  }

  componentWillUnmount() {
    if (this.props.fixed) {
      window.removeEventListener("scroll", this.onScroll.bind(this), false);
    }
    window.removeEventListener(
      "orientationchange",
      this.onOrientationChange.bind(this),
      false
    );
  }

  handlerHide = () => {
    this.setState({ isOpen: false });
  };

  handlerShow = () => {
    this.setState({ isOpen: true });
  };

  handlerYTReady = (e) => {
    this.YT = e.target.getIframe();
    e.target.playVideo();
    const iframe = document.getElementsByClassName("youtube-iframe")[0];

    /* var requestFullScreen = iframe.requestFullScreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen;
        if (requestFullScreen) {
          requestFullScreen.bind(iframe)();
        } */
  };

  nadlerYTEnd = (e) => {
    this.setState({ isOpen: false });
  };

  onScroll = (e) => {
    const elem = this.ref.current;
    if (!elem) {
      return false;
    }
    if (elem.offsetTop !== document.documentElement.scrollTop) {
      this.setState({ isFixed: false });
    } else {
      this.setState({ isFixed: true });
    }
  };

  onOrientationChange = (e) => {
    if (this.YT) {
      const requestFullscreen =
        this.YT.requestFullscreen ||
        this.YT.mozRequestFullScreen ||
        this.YT.webkitRequestFullScreen ||
        this.YT.msRequestFullscreen;
      if (requestFullscreen) {
        requestFullscreen.bind(this.YT)();
      }
    }
  };
}

export default BlockRTV;
