import React from "react";
import { Modal } from "react-bootstrap";

function ModalDialog(props) {
  /* 
        show: _bool_,
        onHide: _callback_,
        header: '',
        body: '',
        footer: ''
    */
  const handleLock = () => {
    document.getElementsByTagName("body")[0].classList.add("locked");
  };
  const handleUnlock = () => {
    document.getElementsByTagName("body")[0].classList.remove("locked");
  };
  return (
    <Modal
      onEnter={handleLock}
      onExited={handleUnlock}
      className={props.className}
      show={props.show}
      onHide={props.hideCallback}
    >
      <Modal.Header closeButton>{props.header}</Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>{props.footer}</Modal.Footer>
    </Modal>
  );
}

export default ModalDialog;
