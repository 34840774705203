import React from "react";

import "./index.scss";

function RutubeVideo(props) {
	let video = props.video;
	let w = window.screen.availWidth;
	let h = window.screen.availHeight - 66;
	return (<div className="__RutubeVideo">
		<iframe
			width={w}
			height={h}
			src={`https://rutube.ru/play/embed/${props.video}`}
			frameBorder="0"
			allow="clipboard-write; autoplay"
			webkitAllowFullScreen
			mozallowfullscreen
			allowFullScreen
		></iframe>
    </div>);
}

export default RutubeVideo;
