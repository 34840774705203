import { Link } from "react-router-dom";
import Button from "./Button";
import React, { useState } from "react";
import classnames from "classnames";

const SetProduct = ({ setType, product, setId }) => {
  const [feedPosition, setFeedposition] = useState(0);
  const gift = product.discount === 100 ? true : false;

  const getOptimalPrice = (product) => {
    const price =
      product.colors.length > 1
        ? product.colors[feedPosition]["newPrice"] !==
          product.colors[feedPosition]["oldPrice"]
          ? product.colors[feedPosition]["newPrice"]
          : product.colors[feedPosition]["oldPrice"]
        : product.price_base !== product.price_discount
        ? product.price_discount
        : product.price_base;

    return toNumber(price);
  };

  const getDiscountPrice = (product) => {
    const price =
      product.colors.length > 1
        ? toNumber(product.colors[feedPosition]["newPrice"])
        : toNumber(product.price_discount);

    return price;
  };

  const getBasePrice = (product) => {
    const price =
      product.colors.length > 1
        ? toNumber(product.colors[feedPosition]["oldPrice"])
        : toNumber(product.price_base);

    return price;
  };

  const toNumber = (number) => {
    if (Number.isInteger(number)) return number;

    return Number(number.replace(/[^0-9\.-]+/g, ""));
  };

  const getProductSection = (product) => {
    let section;
    if (product.sections[0] !== "massagers") {
      section = product.sections[0];
    } else {
      section = product.sections[1];
    }
    return section;
  };

  return (
    <div className="complect-card__item">
      {gift && (
        <div className="complect-card__item-label">
          <div className="label-brain">
            ваш
            <br />
            подарок
          </div>
        </div>
      )}

      {product.colors[feedPosition] && (
        <div className="complect-card__item-image">
          <Link
            to={
              product.colors.length > 1
                ? `${product.link}${product.colors[feedPosition]["code"]}`
                : `/${getProductSection(product)}/${product.code}`
            }
          >
            <img
              src={product.colors[feedPosition]["image"]}
              alt="Product image"
            />
          </Link>
        </div>
      )}

      {product.colors.length > 1 && (
        <div className="complect-card__item-color-list">
          {product.colors.map((color, key) => (
            <div
              key={key}
              className={classnames("complect-card__item-color-list-item", {
                "complect-card__item-color-list-item--active":
                  key === feedPosition,
              })}
              style={{
                borderColor:
                  key === feedPosition ? color.colorCode : "transparent",
              }}
              onClick={() => setFeedposition(key)}
            >
              <div
                style={{
                  backgroundColor: color.colorCode
                    ? color.colorCode
                    : "transparent",
                }}
              />
            </div>
          ))}
        </div>
      )}

      <div className="complect-card__item-title">{product.name}</div>

      <div className="complect-card__item-category">{product.sub}</div>

      {product.colors[feedPosition] && setType != "set" && (
        <>
          <div className="complect-card__item-discount-price">
            {getOptimalPrice(product).toLocaleString("ru-RU")} ₽
          </div>

          {getBasePrice(product) != getDiscountPrice(product) && (
            <div className="complect-card__item-price">
              {getBasePrice(product).toLocaleString("ru-RU")} ₽
            </div>
          )}
        </>
      )}

      <div className="complect-card__item-link">
        <Link
          to={
            product.colors.length > 1
              ? `${product.link}${product.colors[feedPosition]["code"]}`
              : product.link
          }
        >
          <Button label="Подробнее" type="link" arrowRight textCenter />
        </Link>
      </div>
    </div>
  );
};

export default SetProduct;
