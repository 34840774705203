import React from "react";
import GlobalContext from "../../GlobalContext";
import { default as ButtonStyle } from "../Button";
import "./scss/index.scss";

class Button extends React.Component {
  constructor(props, context) {
    super(props);

    context.showMoreState[this.props.id] = this.props.hasOwnProperty("isOpen")
      ? this.props.isOpen
      : false;

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const isOpen = this.props.hasOwnProperty("isOpen")
      ? this.props.isOpen
      : this.context.showMoreState[this.props.id];

    this.context.toggleShowMoreState(this.props.id, !isOpen);

    if (!isOpen === true && this.props.dependence) {
      this.context.toggleShowMoreState(this.props.dependence, !!isOpen);
    }
  }

  render() {
    const isOpen = this.context.showMoreState[this.props.id];

    let label = "";

    if (isOpen === true) {
      label = this.props.hideText;
    } else {
      label = this.props.showText;
    }

    return (
      ((isOpen && !this.props.hideOnOpen) ||
        (!isOpen && this.props.hideOnOpen) ||
        (!isOpen && !this.props.hideOnOpen)) && (
        <ButtonStyle
          type="ShowMore"
          isOpen={isOpen}
          label={label}
          onClick={this.toggle}
          textCenter={this.props.textCenter}
          textWhite={this.props.textWhite}
        />
      )
    );
  }
}

Button.contextType = GlobalContext;

export default Button;
