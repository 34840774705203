import React from "react";
import "../css/CheckList.scss";

const CheckList = {
  Wrapper: CheckListWrapper,
  Item: CheckListItem,
};

function CheckListWrapper(props) {
  return (
    <div className="__CheckList" style={props.style}>
      <ul>{props.children}</ul>
    </div>
  );
}
function CheckListItem(props) {
  let fill = "#000000";
  if (props.fill) fill = props.fill;

  const listStyle = {
    color: fill,
    fontWeight: "500",
  };
  if (props.color) listStyle.color = props.color;
  if (props.weight) listStyle.fontWeight = props.weight;

  let icon = "";
  switch (props.type) {
    case "circle":
      {
        icon = (
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4" cy="4" r="3.5" stroke="#D71B39" />
          </svg>
        );
      }
      break;
    default: {
      icon = (
        <svg
          width="13"
          height="11"
          viewBox="0 0 13 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.50195 7.54364L11.2912 0L12.4991 1.08707L4.66335 9.79339L0 6.04272L1.0403 4.79435L4.50195 7.54364Z"
            fill={fill}
          />
        </svg>
      );
    }
  }

  return (
    <li>
      {icon}
      <span style={listStyle}>{props.children}</span>
    </li>
  );
}

export default CheckList;
