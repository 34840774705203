import React from "react";
import PopupManager from "../PopupManager.js";
import GlobalContext from "../GlobalContext";
import Popup from "./Popup.js";
import FormatText from "./FormatText.js";
import TCaption from "./TCaption.js";
import ButtonBuy from "./ButtonBuy.js";
import IconAddToBasket from "../img/icons/basket.svg";
import GTMClassWrapper from "./GTMClassWrapper";
import "../css/ButtonOrder.scss";
import $ from "jquery";
//import FormMaterial from "./FormMaterial.js";

const FormMaterial = React.lazy(() => import("./FormMaterial.js"));

class ButtonOrder extends React.Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  addToBasket = (id) => {
    let product = this.context.products[id];
    if (!product) {
      for (let pid in this.context.products) {
        const tmpProduct = this.context.products[pid];
        if (!!tmpProduct.colors) {
          tmpProduct.colors.forEach((color, i) => {
            if (color.id == id) product = color;
          });
        }
        if (!!product) {
          let isInBasket = false;
          for (let i in this.context.basket) {
            if (this.context.basket[i].PRODUCT_ID == id) {
              this.context.basket[i].QUANTITY++;
              isInBasket = true;
              break;
            }
          }
          if (!isInBasket) {
            this.context.basket.push({
              ID: this.context.basket.length,
              PRODUCT_ID: id,
              IMAGE: product.image,
              QUANTITY: 1,
              PRICE: {
                BASE: parseInt(product.oldPrice.replace(" ", "")),
                DISCOUNT: parseInt(product.newPrice.replace(" ", "")),
              },
              NAME: tmpProduct.name,
              SECTION: tmpProduct.section,
              SUBHEADER: tmpProduct.sub,
            });
            break;
          } else {
            break;
          }
        }
      }
    } else {
      let isInBasket = false;
      for (let i in this.context.basket) {
        if (this.context.basket[i].PRODUCT_ID == id) {
          this.context.basket[i].QUANTITY++;
          isInBasket = true;
          break;
        }
      }
      if (!isInBasket) {
        this.context.basket.push({
          ID: this.context.basket.length,
          PRODUCT_ID: id,
          IMAGE: product.image,
          QUANTITY: 1,
          PRICE: {
            BASE: product.price_base,
            DISCOUNT: product.price_discount,
          },
          NAME: product.name,
          SECTION: product.section,
          SUBHEADER: product.sub,
        });
      }
    }
    window.localStorage.setItem("basket", JSON.stringify(this.context.basket));
    window.location.href = "/personal/shopcart/";
  };

  render() {
    if (this.state.name) {
      const { product } = this.props;
      let discount = false;
      if (
        this.state.oldPrice.replace(" ", "") !==
        this.state.newPrice.replace(" ", "")
      ) {
        discount = true;
      }

      let button = "";
      if (this.state.available || (!!product && product.code == 'pollux')) {
        if (this.props.circle) {
          button = (
            <div style={{ padding: "12px" }}>
              <img src={IconAddToBasket} alt="" />
            </div>
          );
        } else if (this.props.short) {
          button = (
            <ButtonBuy red placeholder>
              <div style={{ width: "230px" }}>{!!this.state.available ? 'Купить' : 'Предзаказ'}</div>
            </ButtonBuy>
          );
        } else {
          button = (
            <GTMClassWrapper className="gtm_btn_bottom_zakaz">
              <div className="buyButton">
				{!!this.state.available ? "Купить " : "Предзаказ "}
                {!this.props.customTitle
                  ? this.state.name
                  : this.props.customTitle}
              </div>
            </GTMClassWrapper>
          );
        }
      }

      return (
        <PopupManager>
          <Popup
            popupId="popup-order"
            hook
            show={false}
            backdrop
            animation
            closeButton={false}
            button={button}
            submitClass="submit-button-red"
          >
            {{
              header: "",
              body: (
                <>
                  <div className="hidden-on-result">
                    <div style={{ textAlign: "left", position: "relative" }}>
                      <div className="button-order-header">
                        <div className="button-order-header__image">
                          <img
                            src={`https://m.casada-russia.ru${this.state.image}`}
                            alt={this.state.name}
                            className="img-fluid"
                          />
                        </div>

                        <div className="button-order-header__details">
                          <div className="button-order-header__details-name">
                            {this.state.name}
                          </div>
                          <div className="button-order-header__details-description">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.description,
                              }}
                            />
                          </div>
                          <TCaption.Wrapper left>
                            <TCaption.Spacer height="10px" />
                            {discount ? (
                              <TCaption.OldPrice
                                style={{
                                  marginLeft: "5px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {this.state.oldPrice}&nbsp;₽
                              </TCaption.OldPrice>
                            ) : (
                              ""
                            )}
                            <TCaption.Sub
                              style={{
                                fontWeight: "600",
                                whiteSpace: "nowrap",
                                padding: 0,
                              }}
                            >
                              {this.state.newPrice}&nbsp;₽
                            </TCaption.Sub>
                          </TCaption.Wrapper>
                        </div>
                      </div>

                      {this.state.product.colors.length > 1 ? (
                        <div className="colors">
                          <div className="delimiter" />
                          <div className="hint">Цвет</div>
                          {this.state.product.colors.map((color, i) => (
                            <div
                              data-pos={i}
                              onClick={this.handlerColorSwitch}
                              key={i}
                              className={`color-button${
                                i === parseInt(this.state.feedPosition)
                                  ? " active"
                                  : ""
                              }`}
                              style={{ borderColor: color.colorCode }}
                            >
                              <div
                                style={{
                                  background: !!color.gradient
                                    ? color.gradient
                                    : color.colorCode,
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <GTMClassWrapper className="gtm_btn_popupform_addtocart">
                    <button
                      onClick={this.addToBasket.bind(this, this.state.id)}
                      className="button-form-send btn btn-default add_to_shopcart"
                      data-id={this.state.id}
                    >
                      <div className="submit-button-red">
                        Добавить в корзину
                      </div>
                    </button>
                  </GTMClassWrapper>
                  <div className="delimiter-stroke">
                    <div>или</div>
                  </div>

                  <React.Suspense fallback={<></>}>
                    <FormMaterial
                      formId="form-order"
                      submitUrl={`https://dev.casada-russia.ru/api/bitrix/form/product_order`}
                      method="POST"
                      submitText={
                        <div className="submit-button-white">
                          Купить в 1 клик
                        </div>
                      }
                      submitClass="gtm_btn_popupform_1click"
                      parentPopup="popup-order"
                      watchValidate={true}
                      scrollToBottomOnOpen
                    >
                      {[
                        {
                          type: "phone",
                          name: "phone",
                          label: "Ваш номер телефона",
                          placeholder: "Телефон",
                          required: true,
                        },
                        {
                          type: "hidden",
                          name: "product_id",
                          value: this.state.id,
                        },
                        {
                          type: "hidden",
                          name: "product_price",
                          value: this.state.newPrice
                            ? this.state.newPrice
                            : this.state.oldPrice,
                        },
                        {
                          type: "hidden",
                          name: "criteo_id",
                          value: new Date().getTime(),
                        },
                      ]}
                    </FormMaterial>
                  </React.Suspense>

                  <div className="hidden-on-result">
                    <FormatText type="text-small">
                      Оформляя заказ, вы даете согласие на <br />
                      <span
                        onClick={this.context.togglePrivacyAgree}
                        className="link-policy hl-red"
                      >
                        обработку персональных данных
                      </span>
                    </FormatText>
                  </div>
                </>
              ),
              footer: "",
              cancel: {
                // text: "",
                // callback: handleCookieDecline
              },
              accept: {
                // text: submitButton
                // callback: handleCookieAccept
              },
            }}
          </Popup>
        </PopupManager>
      );
    }
    return <div style={{ display: "none" }} />;
  }

  componentWillMount = () => {
    /*нужно добавить проверку на отсутствие товара в фиде*/
    let { feedPosition, product } = this.context.getProductParams();
    if (!!this.props.feedPosition) {
      feedPosition = this.props.feedPosition;
    }
    if (!product && !!this.props.product) {
      product = this.props.product;
    }
    if (!!product) {
      var { name } = product;
      var id = this.props.productId
        ? this.props.productId
        : product.colors[feedPosition].id;
      var { image, newPrice, oldPrice } = product.colors[feedPosition];
      var description = product.sub;
      var available = product.available;
    } else {
      var id = parseInt(this.props.productId);
      if (!product && id > 0) {
        product = this.context.products[id];
      }
      if (!!product && this.props.products[id]) {
        var { name } = this.props.products[id];
        var { image } = this.props.products[id].colors[0];
        var description = this.props.products[id].sub;
        var newPrice = this.props.products[id].colors[0].newPrice.replace(
          " ₽",
          ""
        );
        var oldPrice = this.props.products[id].colors[0].oldPrice.replace(
          " ₽",
          ""
        );
        feedPosition = false;
        var available = product.available;
      } else {
        var available = false;
      }
    }
    const newState = {
      id,
      product,
      name,
      image,
      description,
      newPrice,
      oldPrice,
      feedPosition,
      available,
    };

    this.setState(newState);
  };

  handlerColorSwitch = (e) => {
    const pos = $(e.target).data("pos")
      ? $(e.target).data("pos")
      : $(e.target).parent().data("pos");
    let product;
    if (!!this.props.productId && !!this.props.products) {
      product = this.props.products[this.props.productId];
    } else {
      product = this.props.product;
    }
    const newState = {
      id: product.colors[pos].id,
      image: product.colors[pos].image,
      newPrice: product.colors[pos].newPrice,
      oldPrice: product.colors[pos].oldPrice,
      feedPosition: pos,
    };
    this.setState(newState);
  };
}
export default ButtonOrder;
